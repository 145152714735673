/**
 * Maps Sanity properties for better use
 * @param {SanityMotorcycle} motorcycle
 */
const mapMotorcycleProps = ({
  title,
  price,
  isSold,
  slug,
  year,
  mileage,
  engine,
  featuredImage,
  isOnSalePending,
  hasLogo,
}) => ({
  title,
  price,
  engine,
  mileage,
  date: year,
  isSold,
  slug: slug.current,
  isOnSalePending,
  featuredImage: featuredImage.image.asset.fluid,
  hasLogo,
});

/**
 * Flattens graphql edges array
 * @param {Array} edges Holds list of graphql nodes
 */
const flattenGraphqlNodes = ({ edges }) => {
  if (!edges || !edges.length) return [];

  return edges.map(({ node }) => node);
};

export default { mapMotorcycleProps, flattenGraphqlNodes };
