/* eslint-disable react/prop-types */

import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';

import { Layout, BikeCard, FiltersForm } from '../components';
//
import './motorcycles.scss';
import motorcycleUtils from '../utils/motorcycles';

const initialFiltersState = {
  searchTitleTerm: '',
  //
  selectedMakeId: null,
  selectedModelId: null,
  selectedMaxYear: null,
  selectedMaxMileage: null,
  // price
  selectedMinPrice: 0,
  selectedMaxPrice: 0,
  priceFilterApplied: false,
};

const MotorcyclesPage = props => {
  const {
    data: { allMotorcycles, allModels, allMakes, allFile },
  } = props;

  const [initialMotorcycles] = useState(motorcycleUtils.flattenGraphqlNodes(allMotorcycles));

  // todo: set correct default values
  const [filtersState, setFilters] = useState(initialFiltersState);

  const filteredMotorcycles = React.useMemo(() => {
    let filteredArray = initialMotorcycles;
    const {
      searchTitleTerm,
      //
      selectedMakeId,
      selectedModelId,
      selectedMaxYear,
      // selectedMaxMileage,
      //
      selectedMinPrice,
      selectedMaxPrice,
    } = filtersState;

    // filter by make
    if (selectedMakeId) {
      filteredArray = filteredArray.filter(({ make }) => make.id === selectedMakeId);
    }

    // filter by model
    if (selectedModelId) {
      filteredArray = filteredArray.filter(({ type }) => type.id === selectedModelId);
    }

    // filter by title (search)
    if (searchTitleTerm) {
      filteredArray = filteredArray.filter(({ title }) => {
        // escape string manipulation errors
        if (typeof title !== 'string') return false;

        return title.toLowerCase().includes(searchTitleTerm.toLowerCase());
      });
    }

    // filter by price range
    const minPrice = Number(selectedMinPrice);
    const maxPrice = Number(selectedMaxPrice);
    if (maxPrice >= minPrice) {
      filteredArray = filteredArray.filter(({ price }) => {
        const motorcyclePrice = Number(price);
        //
        if (motorcyclePrice === 0) return true;

        // is in range filter
        if (minPrice && maxPrice) {
          const isMotorcyclePriceInRange = motorcyclePrice >= minPrice && motorcyclePrice <= maxPrice;

          return isMotorcyclePriceInRange;
        }

        // only min filter
        if (minPrice) return motorcyclePrice >= minPrice;

        // only max filter
        if (maxPrice) return motorcyclePrice <= maxPrice;

        // otherwise don't filter at all
        return true;
      });
    }

    // filter by mileage
    // const maxMileage = Number(selectedMaxMileage);
    // if (maxMileage) {
    //   filteredArray = filteredArray.filter(({ mileage }) => {
    //     const motorcycleMileage = Number(mileage);
    //     //
    //     if (!motorcycleMileage) return false;

    //     return motorcycleMileage <= maxMileage;
    //   });
    // }

    // filter by year (<= price)
    const maxYear = Number(selectedMaxYear);
    if (maxYear) {
      filteredArray = filteredArray.filter(({ year }) => {
        const motorcycleYear = Number(year);
        //
        if (!motorcycleYear) return false;

        return motorcycleYear <= maxYear;
      });
    }

    // todo: to be extended as improvement
    return filteredArray.sort(({ price: aPrice }, { price: bPrice }) => {
      return bPrice - aPrice;
    });
  }, [filtersState]);

  const handleFilterChange = newFiltersState => setFilters({ ...filtersState, ...newFiltersState });
  const handleResetFilters = () => setFilters(initialFiltersState);

  const logo = allFile?.edges?.[0]?.node || null;
  const logoFixedImage = logo?.image?.fixed || null;

  return (
    <Layout>
      <div className="motorcycles-page">
        <div className="container">
          <section className="section-header">
            <h1 className="section-title n-b-m n-t-m text-black small">
              Contact JP Superbikes Superstore and our Service Center
            </h1>
            <ul className="bread-crumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current-bread-crumb">Used motorcycles</li>
            </ul>
          </section>
        </div>
        <hr />
        <div className="container">
          <div className="single-related-products">
            <FiltersForm
              defaultState={filtersState}
              makes={motorcycleUtils.flattenGraphqlNodes(allMakes)}
              models={motorcycleUtils.flattenGraphqlNodes(allModels)}
              //
              onChange={handleFilterChange}
              onReset={handleResetFilters}
            />
            <div className="motorcycles-grid">
              {filteredMotorcycles.length ? (
                filteredMotorcycles.map(motorcycle => (
                  <BikeCard
                    key={motorcycle.id}
                    {...motorcycleUtils.mapMotorcycleProps(motorcycle)}
                    logoFixedImage={logoFixedImage}
                  />
                ))
              ) : (
                <h3 className="n-b-m n-t-m text-black small">No results</h3>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MotorcyclesPage;

export const query = graphql`
  query {
    allMotorcycles: allSanityMotorcycle {
      edges {
        node {
          vin
          id
          slug {
            current
          }
          year
          title
          status
          price
          mileage
          isSold
          isOnSalePending
          featured
          engine
          description
          cylinders
          hasLogo
          featuredImage {
            image {
              asset {
                fluid(maxWidth: 1000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          color {
            id
            name
          }
          make {
            id
            name
          }
          type {
            id
            name
          }
        }
      }
    }
    allMakes: allSanityMake {
      edges {
        node {
          id
          name
        }
      }
    }
    allModels: allSanityMotorcycleType {
      edges {
        node {
          id
          name
        }
      }
    }
    allFile(filter: { name: { eq: "jpsstore-logo" } }) {
      edges {
        node {
          name
          image: childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
